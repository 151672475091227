<template>
  <div class="exercises">
    <!--        正式页面的练习题-->
    <!--        <div class="search">-->
    <!--            <el-select-->
    <!--                    v-model="questionType"-->
    <!--                    clearable-->
    <!--                    @change="onLoad"-->
    <!--                    @clear="onLoad"-->
    <!--                    placeholder="选择题型">-->
    <!--                <el-option-->
    <!--                        v-for="item in ea_question_type"-->
    <!--                        :key="item.value"-->
    <!--                        :label="item.label"-->
    <!--                        :value="item.value">-->
    <!--                </el-option>-->
    <!--            </el-select>-->
    <!--            <el-select-->
    <!--                    v-model="type"-->
    <!--                    clearable-->
    <!--                    @change="onLoad"-->
    <!--                    @clear="onLoad"-->
    <!--                    placeholder="选择类型">-->
    <!--                <el-option-->
    <!--                        v-for="item in ea_question_category"-->
    <!--                        :key="item.value"-->
    <!--                        :label="item.label"-->
    <!--                        :value="item.value">-->
    <!--                </el-option>-->
    <!--            </el-select>-->
    <!--            <el-select-->
    <!--                    v-model="level"-->
    <!--                    clearable-->
    <!--                    @change="onLoad"-->
    <!--                    @clear="onLoad"-->
    <!--                    placeholder="选择难度">-->
    <!--                <el-option-->
    <!--                        v-for="item in ea_question_difficulty"-->
    <!--                        :key="item.value"-->
    <!--                        :label="item.label"-->
    <!--                        :value="item.value">-->
    <!--                </el-option>-->
    <!--            </el-select>-->
    <!--            <el-select-->
    <!--              v-model="status"-->
    <!--              clearable-->
    <!--              @change="onLoad"-->
    <!--              @clear="onLoad"-->
    <!--              placeholder="选择完成情况">-->
    <!--            <el-option-->
    <!--                v-for="item in statusArr"-->
    <!--                :key="item.value"-->
    <!--                :label="item.label"-->
    <!--                :value="item.value">-->
    <!--            </el-option>-->
    <!--          </el-select>-->
    <!--        </div>-->

    <div class="exBox">
      <div v-loading="loading" class="box" element-loading-background="rgba(0, 0, 0, 0)">
        <div v-if="total!=0">
          <el-table
            :data="tableData"
            :header-cell-style="{backgroundColor:'#fafbfb',textAlign:'center',fontSize: '16px',fontWeight:500,color:'#000'}"
            :row-style="{marginTop:'16px',borderSpacing:'20px'}"
            :cell-style="{textAlign:'center',color:'#000'}"
            style="width: 100%;height: 770px;
                    background: #F2F4FF;
                    border:1px solid #EFEFEF"
          >
            <el-table-column
              fixed
              prop="questionName"
              label="题目名称"
              width="350"
            />
            <el-table-column
              prop="questionType"
              label="题型"
              :formatter="typeFormat"
              width="150"
            />
            <el-table-column
              prop="type"
              label="类型"
              :formatter="categoryFormat"
              width="150"
            />
            <el-table-column
              prop="level"
              label="难度"
              :formatter="levelFormat"
              width="150"
            />
            <el-table-column
              prop="status"
              label="完成情况"
              :formatter="statusFormat"
              width="150"
            />
            <el-table-column
              fixed="right"
              label="操作"
              width="150"
            >
              <template slot-scope="scope">
                <!--                            <el-button @click="changeStatus(scope.row)" type="text" size="small">{{scope.row.status===0?'未完成':'已完成'}}</el-button>-->
                <!--                            <el-button type="text" size="small" @click="getDetail(scope.$index)">查看</el-button>-->
                <el-button type="text" size="medium" @click="goDetail(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <div style="text-align: right;" class="page">
              <el-pagination
                background
                :current-page="currentPage"
                :page-size="pageSize"
                layout=" prev, pager, next, jumper"
                :total="total"
                @current-change="currentChange"
              />
            </div>
          </div>
        </div>
        <el-empty v-else style="min-height: 500px" class="none-box" description="暂无数据" :image="require('@/assets/images/empty.png')" />
      </div>
    </div>
    <indexBottom />
  </div>
</template>

<script>
import indexBottom from '../../components/indexBottom'
import { getExeEdit, getExeList } from '../../api/course'
import { mapState } from 'vuex'
export default {
  name: 'Exercises',
  components: { indexBottom },
  computed: {
    ...mapState({
      ea_question_type: state => state.dict.dictTree.ea_question_type['arr'],
      ea_question_category: state => state.dict.dictTree.ea_question_category['arr'],
      ea_question_difficulty: state => state.dict.dictTree.ea_question_difficulty['arr']
    })
  },
  created() {
    this.onLoad()
  },
  data() {
    return {
      loading: true,
      topic: '',
      type: '',
      questionType: '',
      level: '',
      options: [],
      currentPage: 1,
      pageSize: 12,
      total: 0,
      tableData: [],
      status: '',
      statusArr: [
        {
          label: '未完成',
          value: '0'
        },
        {
          label: '已完成',
          value: '1'
        }
      ]
    }
  },
  methods: {
    // 去详情页面
    goDetail(data) {
      console.log(this.tableData)
      // if(data.isPublished===0||data.isPublished===null){
      this.$router.push({
        params: data,
        name: 'exercisesDetail',
        query: {
          id: data.publishId
        }
      })
      // }else{
      //     this.$router.push({params:data,name:'homeworkList',query: {id: data.taskLogId }})
      // }
    },
    onLoad(obj) {
      const data = {
        studentId: this.$store.state.user.userInfo.id,
        pageSize: this.pageSize,
        pageNum: this.currentPage
      }
      if (obj) {
        data.questionType = obj.questionType
        data.type = obj.type
        data.level = obj.level
        data.status = obj.status
      }
      console.log('上传的obj', data)
      getExeList(data).then(res => {
        console.log('返回的数据', res)
        if (res.code === 200) {
          this.loading = false
          this.tableData = res.rows
          console.log(this.tableData)
          this.total = res.total
        }
      })
    },
    // 操作员字典翻译
    typeFormat(row) {
      let name = ''
      this.ea_question_type.forEach(function(item) {
        if (row.questionType == item.value) {
          name = item.label
        }
      })
      return name
    },
    categoryFormat(row) {
      let name = ''
      this.ea_question_category.forEach(function(item) {
        if (row.type == item.value) {
          name = item.label
        }
      })
      return name
    },
    levelFormat(row) {
      let name = ''
      this.ea_question_difficulty.forEach(function(item) {
        if (row.level == item.value) {
          name = item.label
        }
      })
      return name
    },
    statusFormat(row) {
      let name = ''
      if (row.status == 0) {
        name = '未完成'
      } else {
        name = '已完成'
      }
      return name
    },
    // 切换分页
    currentChange(val) {
      this.currentPage = val
      console.log(val)
      this.onLoad()
    },
    // 完成状态标记
    changeStatus(data) {
      if (data.status == 0) {
        getExeEdit({ id: data.id, status: 1 }).then(res => {
          if (res.code === 200) {
            this.$message.success('操作成功')
            this.onLoad()
          }
        })
      }
    },
    // 详情
    getDetail(data) {
      console.log(data)
      console.log('data', data)
      console.log('this.tableData', this.tableData)
      // eslint-disable-next-line no-debugger
      this.$router.push({ params: { list: this.tableData, index: data }, name: 'exercisesDetail' })
    }
  }
}
</script>

<style scoped lang="less">
    /deep/ .el-table__body{
        border-collapse: separate;
        border-spacing:0 10px;
        /*table-layout:auto !important;*/
    }
    .exercises{
        .search{
            width: 1200px;
            text-align: left;
            margin: 17px auto;
            .el-select{
                margin-right: 10px;
                background: #FFFFFF;
                ::v-deep.el-input__inner{
                    background: #ffffff;
                    border: 1px solid #BFC4CD;
                }
                ::v-deep.el-input__inner::placeholder {
                    color: #999999;
                }
            }
        }
        .exBox{
            width: 1200px;
            min-height: 650px;
            margin: 0 auto;
            .box{
                width: 1200px;
                padding-top: 0;
                padding-bottom: 19px;
                ::v-deep.el-table{
                  background-color: #edf1f7 !important;
                    .el-table__empty-block{
                        width: 100% !important;
                        background: #fff;
                        min-height: 600px;
                    }
                }
            }
        }
        .pageBox{
            width: 1200px;
            margin-top: 10px;
            height: 40px;
            left:30px;
            position: relative;
            .page{
                position: absolute;
                right: 40px;
                bottom: 10px;
                width: auto;
            }
            //::v-deep.el-pagination{
            //    .el-pager{
            //        .number{
            //            color: #999999;
            //        }
            //        .active{
            //            color: #39A5EF;
            //        }
            //        .more{
            //            color: #999999;
            //        }
            //    }
            //}
        }
    }

</style>
